
<template>
    <div class="v-application text-center">
        <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mt-14">
        <h1 class="text-h2 mt-10 mb-10">404 - La page demandée n'existe pas !</h1>
        <p class="text-h5">L'adresse demandée n'existe pas</p>
        <v-btn color="primary" to="/" class="mt-10">Retour à la page d'accueil</v-btn>
    </div>
</template>

<script>

export default {
}
</script>
